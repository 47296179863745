<template>
    <div v-if="builderUrl" style="width: 100%">
        <!--        <p>{{accountId}}</p>-->
        <object
                type="text/html"
                :data="builderUrl"
                style="width: inherit; height: 100%;">
        </object>
    </div>
    <div v-else-if="account" class="builder-empty-state" style="width: 100%">
        <div class="builder-empty" >
            <div>
                <img src="~dashboard/assets/images/lock.svg" alt="No Workflow" />
                <span>
          {{ $t('BUILDER.NO_BUILDER') }}
          <br />
        </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    accountId: [String, Number]
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount'
    }),
    account() {
      return this.getAccount(this.accountId)
    },
    builderUrl() {
      return this.account && this.account.builder_url
    }
  }
}
</script>

<style lang="scss" scoped>
.inbox-empty-state {
  height: 100%;
  overflow: auto;
}

.builder-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      margin: var(--space-normal);
      width: 10rem;
    }

    span {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      text-align: center;
    }
  }
}

.builder-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
