import * as assert from "assert";

class WhatsappTemplateTokenizer {

  patterns = [
    ['text', /^(?:(?!\{\{).|\n|\r)+/],
    ['parameter', /^\{\{\d\}\}/,]
  ]

  read_token = function(input, i) {
    const patterns = this.patterns;
    for (var j = 0; j < patterns.length; j++) {
      var regex = patterns[j][1];
      var result = input.slice(i).match(regex);
      if (result !== null) {
        const text = result[0];
        const token = this.createToken(patterns[j][0], text);
        return [token, i + text.length];
      }
    }
    //assert.fail("exhausted input while lexing token");
  }

  createToken(type, text) {
    let token =  {type, text}
    if (type === 'parameter') {
      token.value = text.replace(/^\{\{(\d)\}\}/, '$1')
    }
    return token
  }

  tokenize = function(input) {
    const tokens = [];
    if (!input) return tokens;

    for (var i = 0; i < input.length;) {
      const result = this.read_token(input, i);
      if (!result) { i++; continue; }
      const token = result[0];
      i = result[1];
      tokens.push(token);
    }
    return tokens;
  }

}

export default WhatsappTemplateTokenizer;
